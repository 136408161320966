import BaseService from '@/services/BaseService'

class BillingService extends BaseService {
  constructor() {
    super()
  }

  makeBilling(data: any, params = {}) {
    this.endPoint = '/billings'
    return this.post(data, params).then(response => {
      return response
    })
  }

  updatePayment(id: string | number, data: any, opts = {}) {
    this.endPoint = `billings/${id}`
    return this.put(data, opts).then(response => {
      return response.data
    })
  }

  deleteBilling(id: string | number, opts = {}) {
    this.endPoint = 'billings'
    return this.delete(id, opts).then(response => {
      return response.data
    })
  }

  getBillings(opts = {}) {
    this.endPoint = 'billings'
    return this.get(opts).then(response => {
      return response.data
    })
  }

  getBillingDetail(id: string | number, opts = {}) {
    this.endPoint = `billings/${id}`
    return this.get(opts).then(response => {
      return response.data
    })
  }
}

export default new BillingService()
