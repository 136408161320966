// node_modules dependencies
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import startCase from 'lodash/startCase'

// Base Page
import BasePage from '@/pages/Base'

// Local Components
import ProductDetail from '@/containers/ProductDetail/ProductDetail.vue'

// Services
import OrdersService from '@/services/Orders'
import BillingsService from '@/services/Billings'

@Component({
  components: { ProductDetail }
})
export default class Invoices extends BasePage {
  constructor() {
    super()
  }

  // Filter
  filterLoading: boolean = false
  filterLabel: string = 'Filter by Status'
  filterHint: string = 'Apply Status Filters Here'
  filterItems: string[] = ['Pending', 'Processing', 'Done']
  filterIcon: string = 'mdi-filter-variant'
  filterSelected: string = ''

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search here...'
  searchHint: string = 'Search Anything Here'
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'Invoice ID',
      align: 'left',
      sortable: true,
      value: 'invoice_code'
    },
    {
      text: 'Order ID',
      align: 'left',
      sortable: false,
      value: 'order_id'
    },
    {
      text: 'User Name',
      align: 'left',
      sortable: false,
      value: 'user.full_name'
    },
    {
      text: 'Total Price',
      align: 'right',
      sortable: true,
      value: 'total'
    },
    {
      text: 'Status',
      align: 'center',
      sortable: true,
      value: 'status'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  // Detail
  tableHeadersDetail: object[] = [
    {
      text: 'Product ID',
      align: 'left',
      sortable: false,
      value: 'product_id'
    },
    {
      text: 'Product Name',
      align: 'left',
      sortable: false,
      value: 'title'
    },
    {
      text: 'Price',
      align: 'right',
      sortable: false,
      value: 'price'
    },
    {
      text: 'Quantity',
      align: 'right',
      sortable: false,
      value: 'quantity'
    },
    {
      text: 'Sub Total',
      align: 'right',
      sortable: false,
      value: 'total'
    }
  ]

  @Watch('tablePagination', { deep: true })
  @Watch('filterSelected', { deep: true })
  @Watch('searchInput', { deep: true })
  onChanged() {
    this.$vuetify.goTo(0)
    this.getInvoices()
  }

  mounted() {
    this.getInvoices()
  }

  getInvoices(): any {
    // parameters that pass to request endPoint
    const opts: any = {
      params: {
        page: this.tablePagination.page,
        limit: this.tablePagination.rowsPerPage,
        sortedBy: this.tablePagination.descending ? 'desc' : 'asc',
        orderBy: this.tablePagination.sortBy,
        include: 'items.ref.details,user'
      }
    }
    if (this.filterSelected != '') opts.params.search = this.filterSelected === 'Done' ? 'Processed' : this.filterSelected
    if (this.searchInput != '') opts.params.search = this.searchInput

    this.tableLoading = true
    OrdersService.getInvoices(opts)
      .then(response => {
        this.tableItems = []

        forEach(response.data, dataInvoice => {
          let invoice = {
            invoiceId: dataInvoice.invoice_code,
            userName: dataInvoice.user.full_name,
            total: this.formatMoney(dataInvoice.total, dataInvoice.currency),
            orderId: dataInvoice.item.ref.order_id,
            status: startCase(dataInvoice.status === 'Processed' ? 'Done' : dataInvoice.status),
            products: [] as object[],
            makeBillingDialog: false
          }

          forEach(dataInvoice.item.ref.details.data, dataProduct => {
            invoice.products.push({
              id: dataProduct.id,
              productId: dataProduct.ref.product_id,
              productName: dataProduct.ref.title,
              price: this.formatMoney(dataProduct.price, dataProduct.currency),
              quantity: this.formatNumber(dataProduct.quantity),
              total: this.formatMoney(dataProduct.total, dataProduct.currency)
            })
          })
          this.tableItems.push(invoice)
        })

        this.tableTotalItems = response.meta.pagination.total
        this.tableLoading = false
      })
      .catch(error => {
        this.tableLoading = false
        this.catchHandler(error)
      })
  }

  // Make Billing for each invoice
  makeBilling(props: any) {
    props.item.makeBillingDialog = false
    this.showLoading({ text: 'Making Billing...' })

    BillingsService.makeBilling({ invoice_id: props.item.invoiceId })
      .then(response => {
        this.closeLoading()
        this.showSnackbar({ text: startCase(response.data.message), color: 'teal', timeout: 2500 })
      })
      .catch(error => this.catchHandler(error))
  }
}
